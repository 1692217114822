import styled from 'styled-components'
import variants, { size } from './styleMixin'
import PropTypes from 'prop-types'
import { layout, position, margin, padding, border, width, height } from 'styled-system'

const Button = styled.button`
  ${variants}
  ${size}
  ${layout}
  ${position}
  ${margin}
  ${padding}
  ${border}
  ${width}
  ${height}
`
Button.defaultProps = {
  variant: 'default',
  appearance: 'primary',
  size: 'medium',
  
}

Button.propTypes = {
  variant: PropTypes.oneOf(['default', 'outline', 'text', 'transparent']),
  appearance: PropTypes.oneOf(['primary', 'secondary']),
}
export default Button;
