import * as React from "react"

const BowIcon = (props) => (
  <svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.66 84.604c13.374 0 24.216-10.842 24.216-24.216 0-13.375-10.842-24.217-24.216-24.217S35.443 47.013 35.443 60.388c0 13.374 10.843 24.216 24.217 24.216Zm-.341-8.186c9.042 0 16.372-7.33 16.372-16.371 0-9.042-7.33-16.372-16.372-16.372s-16.372 7.33-16.372 16.372c0 9.041 7.33 16.371 16.372 16.371Z"
      fill="url(#a)"
    />
    <path
      d="M25.21 82.558v11.596h11.598M93.426 82.558v11.596H81.829M25.21 37.535V25.94h11.598M93.426 37.535V25.94H81.829"
      stroke="#03BFCB"
      strokeWidth={4}
    />
    <circle
      cx={59.66}
      cy={60.388}
      r={32.402}
      stroke="#03BFCB"
      strokeWidth={4}
    />
    <circle cx={59} cy={60} r={11} fill="url(#b)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.25 53.606c0 1.991-1.679 3.606-3.75 3.606-2.071 0-3.75-1.615-3.75-3.606 0-1.992 1.679-3.606 3.75-3.606 2.071 0 3.75 1.614 3.75 3.606ZM61.709 58.758H57.29c-2.922 0-5.29 2.237-5.29 4.996V67h15v-3.246c0-2.76-2.37-4.996-5.291-4.996Z"
      fill="#fff"
    />
    <path
      d="m63.152 60.822 32.74-8.904"
      stroke="#0795A8"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      clipRule="evenodd"
      d="M57.96 64.398a3.41 3.41 0 1 1 3.388-5.92 3.41 3.41 0 0 1-3.388 5.92Z"
      stroke="#0795A8"
      strokeWidth={3}
    />
    <path
      clipRule="evenodd"
      d="m101.572 55.564-6.04-3.457 3.458-6.04 7.121-1.937-3.457 6.04 6.042 3.459-7.124 1.935Z"
      stroke="#0795A8"
      strokeWidth={3}
      strokeLinejoin="round"
    />
    <path
      opacity={0.1}
      fill="#3CE1CD"
      d="M21.8 87.248v-7.08h7.082v7.08zM17.707 76.678v-7.081h7.081v7.081z"
    />
    <path
      opacity={0.4}
      fill="#3CE1CD"
      d="M31.006 87.248v-7.08h7.08v7.08zM26.914 76.678v-7.08h7.081v7.08z"
    />
    <path
      opacity={0.7}
      fill="#3CE1CD"
      d="M40.21 87.248v-7.08h7.082v7.08zM36.117 76.678v-7.08h7.081v7.08z"
    />
    <path
      fill="#3CE1CD"
      d="M49.418 87.248v-7.08h7.08v7.08zM45.324 76.678v-7.08h7.081v7.08z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={59.66}
        y1={36.171}
        x2={59.66}
        y2={84.604}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#03BFCB" />
        <stop offset={1} stopColor="#03BFCB" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={59}
        y1={49}
        x2={59}
        y2={71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#03BFCB" />
        <stop offset={1} stopColor="#03BFCB" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default BowIcon
