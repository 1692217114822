import * as React from "react"

const AvatarTech = (props) => (
  <svg
    width={140}
    height={140}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99 51H41v38h15v-5.301C56 78.342 60.42 74 65.875 74h8.248c5.453 0 9.875 4.342 9.875 9.699L84 89h15V51ZM70 71a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
      fill="url(#a)"
    />
    <path
      d="M31.5 72a1.5 1.5 0 0 0 3 0h-3ZM33 43v-1.5a1.5 1.5 0 0 0-1.5 1.5H33Zm48 0v1.5a1.5 1.5 0 0 0 1.5-1.5H81Zm1.5-5.5a1.5 1.5 0 0 0-3 0h3ZM34.5 72V43h-3v29h3ZM33 44.5h48v-3H33v3ZM82.5 43v-5.5h-3V43h3ZM108.5 68a1.5 1.5 0 0 0-3 0h3ZM107 97v1.5a1.5 1.5 0 0 0 1.5-1.5H107Zm-48 0v-1.5a1.5 1.5 0 0 0-1.5 1.5H59Zm-1.5 5.5a1.5 1.5 0 0 0 3 0h-3Zm48-34.5v29h3V68h-3Zm1.5 27.5H59v3h48v-3ZM57.5 97v5.5h3V97h-3Z"
      fill="#0795A8"
    />
    <path
      clipRule="evenodd"
      d="M81 37a5 5 0 1 1 0-10 5 5 0 0 1 0 10ZM59 113a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z"
      stroke="#0795A8"
      strokeWidth={3}
    />
    <path
      d="M23 101v16h16M117 101v16h-16M23 39V23h16M117 39V23h-16"
      stroke="#03BFCB"
      strokeWidth={4}
    />
    <path opacity={0.1} fill="#3CE1CD" d="M74 57.381V47h10.381v10.381z" />
    <path opacity={0.4} fill="#3CE1CD" d="M87.38 57.38V47h10.382v10.38z" />
    <path opacity={0.7} fill="#3CE1CD" d="M100.762 57.381V47h10.381v10.381z" />
    <path fill="#3CE1CD" d="M114.143 57.381V47h10.381v10.381z" />
    <path opacity={0.1} fill="#3CE1CD" d="M65.523 83v10.38h-10.38V83z" />
    <path opacity={0.4} fill="#3CE1CD" d="M52.143 83v10.381H41.762v-10.38z" />
    <path opacity={0.7} fill="#3CE1CD" d="M38.762 83v10.381H28.38v-10.38z" />
    <path fill="#3CE1CD" d="M25.38 83v10.381H15v-10.38z" />
    <defs>
      <linearGradient
        id="a"
        x1={70}
        y1={51}
        x2={70}
        y2={89}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BDE0" />
        <stop offset={1} stopColor="#0795A8" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default AvatarTech
