import { gql } from "@apollo/client";
import { 
  PERKS_FRAGMENT, 
  TESTIMONIALS_FRAGMENT, 
  OURBENEFITS_FRAGMENT, 
  BRIEF_ABOUT_US_FRAGMENT,
  ASIDE_FRAGMENT
} from '../fragments'

export const GET_HOMEPAGE = gql`
  query GetHomePage {
    ourBenefits:ourBenefitsModelCollection(limit:2){
      items{
        ...OurBenefitsFragment
      }
    }
    formulas:ourFormulaModelCollection(limit:3){
      items{
        stepNumber,
        title,
        description
      }
    },
    briefAboutUs:briefAboutUsModelCollection(limit:1){
      items{
        ...BriefAboutUsFragment
      }
    },
    ourValues: ourValuesModelCollection(limit: 1) {
      items {
        title
        description
      }
    },
    ourServices: ourServicesModelCollection(limit: 1) {
        items {
          label
          title
          perks: perksCollection{
            items{
              ...PerkFragment
            }
          }
        }
      },
    contactUs:contactUsModelCollection(limit:1){
        items{
          label,
          title,
          heading,
          subheading,
          address,
          web,
          email,
          phone,
          socialMedias: socialMediaCollection{
            items{
              url,
              company,
              icon
            }
          },
          form:inputListCollection{
            inputs:items{
              type,
              name,
              id,
              placeholder
            }
          }
          alerts:alertsCollection{
            items{
              type,
              message
            }
          }
      }
    }
  }
  ${PERKS_FRAGMENT}
  ${OURBENEFITS_FRAGMENT}
  ${BRIEF_ABOUT_US_FRAGMENT}
`

export default GET_HOMEPAGE;