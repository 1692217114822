import React from 'react'

const List = ({ items, render }) => {
    return items.map((item, i) => {
      return render({ item, i });
    });
  };

export default List;

