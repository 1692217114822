import styled from "styled-components";
import Text from '../Text'

const HyperLink = styled.a` text-decoration: none;`

const Link = ({path, children, ...rest}) => (
    <HyperLink href={path}>
        <Text {...rest}>{children}</Text>
    </HyperLink>
)

Text.defaultProps = {
    size: 'active',
    appareance: 'primary'
}

export default Link;
