import { variant as Variant} from 'styled-system'

const textColor = (appearance, {primary, secondary, paper}, isHover) => {
    if(appearance === 'secondary'){
      return (isHover) ? paper : secondary;
    }else{
      return (isHover) ? primary : paper;
    }
}

const bgColor = (appearance, {primary,secondary, paper}, isHover) => {
  if(appearance === 'secondary'){
    return (isHover) ? secondary : paper;
  }else{
    return (isHover) ? paper : primary;
  }
}
const borderColor = (appearance, colors, isHover) => textColor(appearance, colors, isHover);

export const variants = ({
    appearance,
    hasHover,
    theme: {
        colors,
    }
}) => {
    return Variant({
        variants: {
            default:{
                //fontFamily: fonts.grotesque,
                cursor: 'pointer',
                color: textColor(appearance, colors),
                bg: bgColor(appearance, colors),
                border: 1,
                borderColor: borderColor(appearance, colors),
                borderRadius: 100,
                '&:hover, &.hover': {
                    border: 1,
                    color: textColor(appearance, colors, true),
                    borderColor:  borderColor(appearance, colors, true),
                    bg: bgColor(appearance, colors, true),
                    'svg':{
                        path: {
                            fill: borderColor(appearance, colors, true),
                        }
                    }
                },
                '&:disabled, &.disabled': {
                    border:0,
                    bg: colors.disabled,
                    color: colors.greys.dusk,
                   
                }
            },
            outline: {
                //fontFamily: fonts.grotesque
                cursor: 'pointer',
                borderRadius: 100,
                border: 1,
                color: colors[appearance],
                borderColor:  colors[appearance],
                bg: colors.white,
                '&:hover, &.hover': {
                  bg: colors[appearance],
                  color: colors.white,
                },
                '&:disabled, &.disabled': {
                    border:0,
                    bg: colors.disabled,
                    color: colors.greys.dusk,
                   
                }
            },
            text: {
                cursor: 'pointer',
                bg: 'transparent',
                border: 0,
                color: colors[appearance],
                padding: 0,
                margin: 0,
                textDecoration: 'none', 
                ...(hasHover)&& {
                    '&:hover, &.hover': {
                        bg: colors[appearance],
                    }
                }
            }
        }
    })
} 

export const size = () => {
    return Variant({
        prop: 'size',
        variants: {
           small: {
            px: 16,
            py: 12,
           },
           medium: {
            px: 32,
            py: 16,
           },
           large:{
            px: 42,
            py: 22,
           }
        }
    })
}

export default variants