import * as React from "react"

const CloseIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.48 3.546A11.965 11.965 0 0 0 12.992.032c-6.628 0-12 5.373-12 12.001 0 3.314 1.343 6.315 3.515 8.487a11.965 11.965 0 0 0 8.486 3.514c6.628 0 12.001-5.373 12.001-12 0-3.315-1.344-6.316-3.516-8.488Zm-1.543 15.427a9.789 9.789 0 0 1-6.943 2.876c-5.423 0-9.819-4.396-9.819-9.819a9.789 9.789 0 0 1 2.876-6.943 9.786 9.786 0 0 1 6.942-2.876c5.422 0 9.818 4.396 9.818 9.818a9.785 9.785 0 0 1-2.876 6.942l.002.002Z"
      fill="#00BDE0"
    />
    <path
      d="m14.537 12.034 3.855-3.855a1.09 1.09 0 0 0-1.541-1.54v-.002l-3.854 3.855-3.855-3.855A1.091 1.091 0 0 0 7.6 8.18H7.6l3.854 3.854L7.6 15.888a1.09 1.09 0 1 0 1.54 1.542h.002l3.854-3.856 3.856 3.855a1.09 1.09 0 1 0 1.541-1.54v-.002l-3.856-3.853Z"
      fill="#00BDE0"
    />
  </svg>
)

export default CloseIcon
