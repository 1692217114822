import React from "react";
import { Flex } from '../../atoms'
import { 
  OurFormulaSection,
  OurServicesSection,
  OurValuesSection,
  WhoWeAreSection,
  WhatWeDoSection,
  GetInTouchSection,
  
 } from '../../organisms'

const HomeTemplate = ({
    ourBenefits,
    aboutUs,
    ourServices,
    ourFormula,
    ourValues,
    contactUs
}) => {
  return (
    <Flex flexDirection="column" >
        { ourBenefits && <WhatWeDoSection {...ourBenefits} />}
        { aboutUs && <WhoWeAreSection {...aboutUs} /> }
        { ourValues && <OurValuesSection {...ourValues}/> }
        { ourServices && <OurServicesSection {...ourServices} /> }
        { ourFormula && <OurFormulaSection {...ourFormula} /> }
        { contactUs && <GetInTouchSection {...contactUs} /> }
    </Flex>
  );
};

export default HomeTemplate;
