import { 
 colorTheme,
 colors
} from './colors'


const breakpoints = [
    '40em',
    '52em',
    '64em',
    '80em'
];

const theme = {
    borders:[
        0,
        '1px solid',
        '2px solid',
        '4px solid',
        '8px solid',
        '16px solid',
        '32px solid',
    ],
    fontSizes: {
        12: '0.75rem',
        14: '0.875rem',
        16: '1rem',
        20: '1.25rem',
        24: '1.5rem',
        30: '1.875rem',
        32: '2rem',
        40: '2.5rem',
        44: '2.75rem',
        46: '2.875rem',
        48: '3rem',
        52: '3.25rem',
        54: '3.375rem',
        56: '3.5rem',
        64: '4rem',
        72: '4.5rem',
        80: '5rem',
    },
    fontWeights: {
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900,
    },
    letterSpacings: {
        0: '0',
        1: '0.0625rem',
        '-1': '-0.0625rem',
        '-2': '-0.125rem',
        '-0.25': '-0.016rem',
        '-0.75': '-0.047rem',
        '-1.25': '-0.078rem',
    },
    lineHeights: {
        1: 1,
        1.18: 1.18,
        1.08: 1.08,
        1.25: 1.25,
        1.3: 1.3, // fontSize 12
        1.43: 1.43, // fontsize 14
        1.5: 1.5, // fontSize 16
    },
    space: {
        '-96': '-6rem',
        '-88': '-5.5rem',
        0: '0',
        1: '0.0625rem',
        2: '0.125rem',
        3: '0.1875rem',
        4: '0.25rem',
        5: '0.3125rem',
        6: '0.375rem',
        7: '0.4375rem',
        8: '0.5rem',
        9: '0.5625rem',
        10: '0.625rem',
        11: '0.6875rem',
        12: '0.75rem',
        13: '0.8125rem',
        14: '0.875rem',
        15: '0.9375rem',
        16: '1rem',
        18: '1.125rem',
        20: '1.25rem',
        22: '1.375rem',
        24: '1.5rem',
        26: '1.625rem',
        28: '1.75rem',
        30: '1.875rem',
        32: '2rem',
        34: '2.125rem',
        36: '2.25rem',
        38: '2.375rem',
        40: '2.5rem',
        42: '2.625em',
        44: '2.75rem',
        48: '3rem',
        50: '3.125rem',
        52: '3.25rem',
        54: '3.375rem',
        56: '3.5rem',
        60: '3.75rem',
        64: '4rem',
        70: '4.375rem',
        72: '4.5rem',
        80: '5rem',
        88: '5.5rem',
        90: '5.625rem',
        96: '6rem',
        100: '6.25rem',
        128: '8rem',
        188: '11.75rem',
      },
    colors: {
        ...colors,
        ...colorTheme,
    },
    fonts: { 
        questrial: `'Questrial', sans-serif`,
        montserrat: `'Montserrat', sans-serif`,
    },
    breakpoints
}

export default theme;