import * as React from "react"

const ProcessIcon = (props) => (
  <svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 35.23C27 34 28.01 33 29.255 33h48.49C78.99 33 80 33.999 80 35.23v53.54C80 90 78.99 91 77.745 91h-48.49C28.01 91 27 90.001 27 88.77V35.23Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 34.946C25 32.766 26.755 31 28.92 31h48.16c2.165 0 3.92 1.766 3.92 3.946v54.109C81 91.234 79.245 93 77.08 93H28.92C26.755 93 25 91.234 25 89.055v-54.11Zm3.92-.564c-.31 0-.56.252-.56.564v54.109c0 .31.25.563.56.563h48.16c.31 0 .56-.252.56-.563v-54.11a.562.562 0 0 0-.56-.563H28.92Z"
      fill="#00BDE0"
    />
    <path
      d="M21 30.28c0-1.26 1.015-2.28 2.267-2.28h46.466A2.273 2.273 0 0 1 72 30.28v52.44c0 1.26-1.015 2.28-2.267 2.28H23.267A2.273 2.273 0 0 1 21 82.72V30.28Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 30.896C20 28.744 21.73 27 23.865 27h45.27C71.27 27 73 28.744 73 30.896v51.208C73 84.256 71.27 86 69.135 86h-45.27C21.73 86 20 84.256 20 82.104V30.896Zm3.865-.556a.554.554 0 0 0-.552.556v51.208c0 .307.247.556.552.556h45.27c.305 0 .552-.249.552-.556V30.896a.554.554 0 0 0-.552-.556h-45.27Z"
      fill="#00BDE0"
    />
    <path
      opacity={0.1}
      fill="#3CE1CD"
      d="M66.201 86.755v-8.248h8.248v8.248zM61.435 74.444v-8.248h8.248v8.248z"
    />
    <path
      opacity={0.4}
      fill="#3CE1CD"
      d="M76.924 86.754v-8.248h8.248v8.248zM72.157 74.444v-8.248h8.248v8.248z"
    />
    <path
      opacity={0.7}
      fill="#3CE1CD"
      d="M87.647 86.754v-8.248h8.248v8.248zM82.878 74.443v-8.248h8.248v8.248z"
    />
    <path
      fill="#3CE1CD"
      d="M98.367 86.755v-8.248h8.248v8.248zM93.601 74.443v-8.248h8.248v8.248z"
    />
    <path fill="url(#a)" d="M27 42h35v5H27z" />
    <path fill="url(#b)" d="M27 53h29v5H27z" />
    <path fill="url(#c)" d="M27 63h27v5H27z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 22.857C38 19.07 41.134 16 45 16s7 3.07 7 6.857V28c0 .631-.522 1.143-1.167 1.143A1.155 1.155 0 0 1 49.667 28v-5.143c0-2.525-2.09-4.571-4.667-4.571s-4.667 2.046-4.667 4.571v12.807c0 1.132.938 2.05 2.093 2.05 1.157 0 2.094-.918 2.094-2.05v-13.24c0-.63.522-1.142 1.166-1.142.645 0 1.167.512 1.167 1.143v13.239c0 2.395-1.982 4.336-4.427 4.336C39.982 40 38 38.059 38 35.664V22.857Z"
      fill="#0795A8"
    />
    <defs>
      <linearGradient
        id="a"
        x1={44.5}
        y1={42}
        x2={44.5}
        y2={47}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#03BFCB" />
        <stop offset={1} stopColor="#03BFCB" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={41.5}
        y1={53}
        x2={41.5}
        y2={58}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#03BFCB" />
        <stop offset={1} stopColor="#03BFCB" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={40.5}
        y1={63}
        x2={40.5}
        y2={68}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#03BFCB" />
        <stop offset={1} stopColor="#03BFCB" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default ProcessIcon
