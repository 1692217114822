import styled from "styled-components";
import { layout } from 'styled-system'

const Image = styled.img.attrs(({url, ...rest})=>({
    src: url,
    ...rest,
}))`
    ${layout}
`

Image.defaultProps = {
    url : 'http://via.placeholder.com/800x500',
    alt: ''
}
export default Image;