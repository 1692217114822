import * as React from "react"

const EmailIcon = (props) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={36} rx={4} fill="#fff" />
    <path
      d="M18 26.333a8.06 8.06 0 0 1-3.23-.656 8.44 8.44 0 0 1-2.656-1.792 8.438 8.438 0 0 1-1.791-2.656A8.059 8.059 0 0 1 9.666 18c0-1.153.219-2.232.657-3.24a8.47 8.47 0 0 1 1.791-2.645 8.451 8.451 0 0 1 2.656-1.793A8.068 8.068 0 0 1 18 9.668c1.152 0 2.232.218 3.238.655a8.469 8.469 0 0 1 4.438 4.438 8.033 8.033 0 0 1 .657 3.24v1.208c0 .82-.281 1.518-.844 2.094-.562.576-1.254.865-2.073.865-.5 0-.965-.111-1.396-.334a3.128 3.128 0 0 1-1.062-.875 4.145 4.145 0 0 1-2.959 1.209c-1.152 0-2.135-.407-2.947-1.22-.813-.812-1.22-1.794-1.22-2.947s.407-2.136 1.22-2.948c.812-.813 1.795-1.219 2.947-1.219 1.153 0 2.136.406 2.949 1.219.812.812 1.218 1.795 1.218 2.948v1.208c0 .403.125.719.375.948.25.23.542.344.875.344.333 0 .625-.115.875-.344.25-.23.375-.545.375-.948V18c0-1.82-.656-3.386-1.97-4.698-1.311-1.313-2.877-1.969-4.697-1.969-1.82 0-3.385.656-4.698 1.969-1.312 1.312-1.968 2.879-1.968 4.698 0 1.82.656 3.385 1.968 4.698 1.313 1.312 2.879 1.969 4.698 1.969h4.167v1.666h-4.167Zm0-5.833c.694 0 1.284-.243 1.77-.73A2.41 2.41 0 0 0 20.5 18a2.41 2.41 0 0 0-.73-1.77A2.411 2.411 0 0 0 18 15.5c-.695 0-1.285.243-1.771.73a2.411 2.411 0 0 0-.73 1.77c0 .694.243 1.285.73 1.77.486.487 1.076.73 1.77.73Z"
      fill="#00BDE0"
    />
  </svg>
)

export default EmailIcon
