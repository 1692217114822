import * as React from "react"

const CodeFileIcon = (props) => (
  <svg
    width={140}
    height={140}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="url(#a)" strokeWidth={4} d="M31 43h64.725v44.638H31z" />
    <path fill="url(#b)" d="M43.275 57.507H108v44.638H43.275z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m85.695 87.742-2.81-2.825 4.99-5.018-5.03-5.06 2.809-2.825 7.84 7.885-7.799 7.843Zm-20.113 0L57.783 79.9l7.84-7.884 2.809 2.825-5.03 5.059 4.989 5.018-2.81 2.825Zm5.89.002h3.932l3.932-15.73h-3.874l-3.99 15.73Z"
      fill="#fff"
    />
    <circle cx={39.5} cy={51.5} r={2.5} fill="#03BFCB" />
    <circle cx={46.5} cy={51.5} r={2.5} fill="#03BFCB" />
    <circle cx={53.5} cy={51.5} r={2.5} fill="#03BFCB" />
    <path
      d="M17.5 80a1.5 1.5 0 0 1 3 0h-3Zm1.5 34v1.5a1.5 1.5 0 0 1-1.5-1.5H19Zm34-1.5a1.5 1.5 0 0 1 0 3v-3ZM20.5 80v34h-3V80h3ZM19 112.5h34v3H19v-3Z"
      fill="#0795A8"
    />
    <path
      clipRule="evenodd"
      d="M58 119a5 5 0 1 1 0-10 5 5 0 0 1 0 10ZM19 80a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z"
      stroke="#0795A8"
      strokeWidth={3}
    />
    <path
      d="M122.5 60a1.5 1.5 0 0 1-3 0h3ZM121 26v-1.5a1.5 1.5 0 0 1 1.5 1.5H121Zm-34 1.5a1.5 1.5 0 0 1 0-3v3ZM119.5 60V26h3v34h-3Zm1.5-32.5H87v-3h34v3Z"
      fill="#0795A8"
    />
    <path
      clipRule="evenodd"
      d="M82 21a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM121 60a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z"
      stroke="#0795A8"
      strokeWidth={3}
    />
    <path
      opacity={0.1}
      fill="#00BDE0"
      d="M125.523 119.381V109h-10.381v10.381z"
    />
    <path
      opacity={0.4}
      fill="#00BDE0"
      d="M112.143 119.381V109h-10.381v10.381z"
    />
    <path opacity={0.7} fill="#00BDE0" d="M98.762 119.381V109H88.38v10.381z" />
    <path fill="#00BDE0" d="M85.38 119.381V109H75v10.381z" />
    <path opacity={0.1} fill="#00BDE0" d="M14 21v10.381h10.381V21z" />
    <path opacity={0.4} fill="#00BDE0" d="M27.38 21v10.381h10.382V21z" />
    <path opacity={0.7} fill="#00BDE0" d="M40.762 21v10.381h10.38V21z" />
    <path fill="#00BDE0" d="M54.143 21v10.381h10.38V21z" />
    <defs>
      <linearGradient
        id="a"
        x1={63.362}
        y1={43}
        x2={63.362}
        y2={87.638}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BDE0" />
        <stop offset={1} stopColor="#0795A8" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={75.638}
        y1={57.507}
        x2={75.638}
        y2={102.145}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BDE0" />
        <stop offset={1} stopColor="#0795A8" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default CodeFileIcon
