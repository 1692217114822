import React from "react";
import { Flex, Text } from '../../atoms'
import { InfoSection, HeaderInfoSection, LeafImage } from "../../molecules";
import ReactMarkdown from "react-markdown";

const WhoWeAreSection = ({ label, title, description }) => (
    <Flex 
        flexDirection="column"
        alignItems="center" 
        as="section" 
        id="who-we-are" 
        pl={["40px","40px", "250px"]} pr={["40px","40px", "250px"]} mt={["40px","40px","80px"]} mb={["40px","40px","80px"]}>
        <HeaderInfoSection>
            <HeaderInfoSection.Title>
                <Text variant="small-regular">{label}</Text>
            </HeaderInfoSection.Title>
            <HeaderInfoSection.Description></HeaderInfoSection.Description>
        </HeaderInfoSection>
    
        <InfoSection gridTemplateColumns={["1fr","1fr", "1fr 1fr"]}>
            <InfoSection.Left justifyContent={["center", "center", "end"]}>
                <Flex flexDirection="column" mt="50" pr="10px" width={["100%", "100%", "65%"]} alignItems={["center", "center", "start"]}>
                    <Text variant="h3" appearance="quarter">{title}</Text>
                    <Text variant="medium-regular" appearance="tertiary" textAlign={["center", "center", "unset"]} >
                        <ReactMarkdown>
                            {description}
                        </ReactMarkdown>
                    </Text>

                </Flex>
            </InfoSection.Left>
            <InfoSection.Right gridRow={["1","1","unset"]}>
                <LeafImage source="/assets/png/team.png" width="300px" height="357px" />
            </InfoSection.Right>     
        </InfoSection>
    </Flex>
)

export default WhoWeAreSection;
