import styled from "styled-components";
import { grid, layout, space, border, backgroundRepeat, backgroundSize , backgroundImage } from 'styled-system'

const GridBox = styled.div`
    display: grid;
    ${grid}
    ${layout}
    ${space}
    ${border}
    ${backgroundImage}
    ${backgroundRepeat}
    ${backgroundSize}
`

export default GridBox;
