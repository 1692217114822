import * as React from "react"

const AddressIcon = (props) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={36} rx={4} fill="#fff" />
    <path
      d="M23.625 16.125a5.625 5.625 0 1 0-11.25 0c0 2.308 1.846 5.315 5.625 8.918 3.779-3.603 5.625-6.61 5.625-8.918ZM18 26.75c-4.584-4.166-6.875-7.709-6.875-10.625a6.875 6.875 0 1 1 13.75 0c0 2.916-2.291 6.459-6.875 10.625Z"
      fill="#00BDE0"
    />
    <path
      d="M18 16.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM18 18a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm6.75 3.75 2 5h-5.625V25.5h-6.25v1.25H9.25l2-5h13.5Zm-1.346 0H12.596l-1.5 3.75h13.808l-1.5-3.75Z"
      fill="#00BDE0"
    />
  </svg>
)

export default AddressIcon
