import React from "react";
import { Flex, Text, Gif } from '../../atoms'
import { InfoSection } from "../../molecules";
import ReactMarkdown from "react-markdown";


const OurValuesSection = ({ title, description }) => (
    <Flex flexDirection="column" as="section" id="our-values" 
        pl={["40px","40px", "250px"]}
        pr={["40px","40px", "250px"]} 
        mt={["40px","40px","80px"]} 
        mb={["40px","40px","80px"]}>
        <InfoSection gridTemplateColumns={["1fr","1fr", "1fr 1fr"]}>
            <InfoSection.Left>
                <Gif source='assets/gif/planet.json' />
            </InfoSection.Left>
            <InfoSection.Right>
                <Flex flexDirection="column" alignItems={["center", "center", "start"]} textAlign={["center", "center", "unset"]} justifyContent="center">
                    <Text variant="h3" appearance="tertiary">{title}</Text>
                    <Text variant="medium-regular" appearance="tertiary" textAlign={["center", "center", "left"]}>
                       <ReactMarkdown>
                          {description}
                       </ReactMarkdown>
                    </Text>
                </Flex>
            </InfoSection.Right>     
        </InfoSection>
    </Flex>
)

export default OurValuesSection;
