import React, { useState } from "react";
import { Flex, Text, GridBox, Button, Svg } from '../../atoms'
import { InfoSection } from "../../molecules";

const NavBar = () => {
    const [expand, setExpand ] = useState(false)
    const handleLink = (e) =>{
        e.preventDefault();
        setExpand(false);
    }
    return (
        <Flex flexDirection="column" width="100%" position="relative" bg={["#F8F9F9","#F8F9F9","transparent"]}>
            <InfoSection gridTemplateColumns={["1fr 1fr", "1fr auto", "40% 60%"]}>
                <InfoSection.Left>
                    <Flex pl={[0,0,50]} justifyContent={["center", "center", "unset"]} width="100%">
                        <Svg as="icon-logo"/>
                    </Flex>
                </InfoSection.Left>
                <InfoSection.Right justifyContent="end" >
                    <Flex pr={[10, 10, 50]} >
                        <GridBox display={["none", "none", "grid"]} gridTemplateColumns="10px 10px 20px auto" gridColumnGap="80">
                            <Button variant="text" p="0" as="a" href="#what-we-do"><Text variant="x-small-regular" appearance="tertiary">HOME</Text></Button>
                            <Button variant="text" p="0" as="a" href="#who-we-are"><Text variant="x-small-regular" appearance="tertiary">ABOUT</Text></Button>
                            <Button variant="text" p="0" as="a" href="/careers"><Text variant="x-small-regular" appearance="tertiary">CAREERS</Text></Button>
                            <Button variant="text" p="0" as="a" href="#our-services"><Text variant="x-small-regular" appearance="tertiary">SERVICES</Text></Button>
                            {/* <Button pt="0" pb="0" display={["none","none","inline"]}>Interested? Contact us</Button> */}
                        </GridBox>
                        <Flex display={["flex", "flex", "none"]}>
                            <Svg as={expand ? "icon-close" : "icon-sandwich"} onClick={()=>setExpand(!expand)}/>
                        </Flex>
                    </Flex>
                </InfoSection.Right>     
            </InfoSection>
            { expand && (
                <Flex height="100vh" flexDirection="column" pt="40px" pb="40px" alignItems="center" position="absolute" mt="5.8rem" width="100%" bg="#F8F9F9" zIndex="1000">
                    <Button variant="text" p="0" as="a" onClick={handleLink} href="#what-we-do"><Text variant="x-small-regular" appearance="tertiary">HOME</Text></Button>
                    <Button variant="text" p="0" as="a" onClick={handleLink} href="#who-we-are"><Text variant="x-small-regular" appearance="tertiary">ABOUT</Text></Button>
                    <Button variant="text" p="0" as="a"  href="/careers"><Text variant="x-small-regular" appearance="tertiary">CAREERS</Text></Button>
                    <Button variant="text" p="0" as="a" onClick={handleLink} href="#our-services"><Text variant="x-small-regular" appearance="tertiary">SERVICES</Text></Button>
                    <Button mb="40" mt="40">Interested? Contact us</Button> 

                    <Flex flexDirection="column" alignItems="center">
                        <Text variant="h5" mt="10" mb="10" appearance="tertiary">Contact Info</Text>
                        <Text variant="x-small-regular" appearance="tertiary">info@thedevcity.com</Text>
                        <Text variant="x-small-regular" appearance="tertiary">+1(978)763-8595</Text>
                    </Flex>
                </Flex>
            )}
        </Flex>
    )
}

export default NavBar;
