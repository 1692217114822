import { useMemo } from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createHttpLink} from 'apollo-link-http'

const CONTENTFUL_SPACE_ID ='0nwcr4g6u7or'
const CONTENTFUL_ACCESS_TOKEN = 'qHDVRWQPvxmY5ZXd68n7lISrgDbhp_QoddEjF5vipeU'
const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/`

const httpLink = createHttpLink({
  uri: CONTENTFUL_URL,
  credentials: 'same-origin',
  headers: {
    authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
  }
});
  
const initializeApollo = () => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
  })
   
  return apolloClient;
}


export const useApollo = () => {
    const client = useMemo(initializeApollo, [])
    return client
}