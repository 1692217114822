import React from "react";
import { Careers as CareersTemplate } from '../../templates'
import { useQuery } from "@apollo/client";
import {  GET_CAREERS_PAGE } from '../../../schemas/queries/careersPage'
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const CareersPage = ({ flags }) => {

  const { loading, data } = useQuery(GET_CAREERS_PAGE)
//   const { 
//     featureDashboardOurValues,
//     featureDashboardOurBenefits,
//     featureDashboardContactus,
//     featureDashboardOurFormula,
//     featureDashboardAboutUs,
//     featureDashboardOurServices
//    } = flags;


console.log('data=>',data)
  const props = {
    careers: {
      ...data?.jobView?.items || []
    }
  }

  return !loading && <CareersTemplate {...props} />
}

export default withLDConsumer()(CareersPage);
