import { gql } from "@apollo/client";

const TESTIMONIALS_FRAGMENT = gql`
  fragment TestimonialFragment on TestimonialModel{
    author,
    company,
    message,
    image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
    }
  }
`

export default TESTIMONIALS_FRAGMENT;