import React from "react";
import { Home as HomeTemplate } from '../../templates'
import { useQuery } from "@apollo/client";
import {  GET_HOMEPAGE } from '../../../schemas/queries/homepage'
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const HomePage = ({ flags }) => {

  const { loading, data } = useQuery(GET_HOMEPAGE)
  const { 
    featureDashboardOurValues,
    featureDashboardOurBenefits,
    featureDashboardContactus,
    featureDashboardOurFormula,
    featureDashboardAboutUs,
    featureDashboardOurServices
   } = flags;

  const props = {
    ourBenefits: featureDashboardOurBenefits ?
    {
      ...data?.ourBenefits?.items[0]
    } : null,    
    aboutUs: featureDashboardAboutUs ? {
      ...data?.briefAboutUs?.items[0]
     } : null,
    ourValues: featureDashboardOurValues ? {
        ...data?.ourValues?.items[0]
    } : null,
    ourServices: featureDashboardOurServices ? {
      ...data?.ourServices?.items[0]
     } : null,
    ourFormula: featureDashboardOurFormula ? {
      ...data?.formulas?.items[0],
     } : null,
    contactUs: featureDashboardContactus ? {
      ...data?.contactUs?.items[0],
      cta: {
        type: 'button',
        text: 'Send a request',
        onClick: () => {}
      }
     } || null : null
  }

  return !loading && <HomeTemplate {...props} />
}

export default withLDConsumer()(HomePage);
