import React from "react";
import { Flex, Text, Button, GridBox, Svg, List } from '../../atoms'
import { InfoSection } from "../../molecules";


const GetInTouchSection = ({
    label,
    title,
    heading,
    subheading,
    email,
    phone,
    address,
    form,
    cta
}) => {
    const textInputs = form.inputs.filter((input)=>  input.type === "text"  ||  input.type === "email" );
    const textAreaInput = form.inputs.filter((input)=>  input.type === "textarea");
    return (
    <Flex flexDirection="column" as="section" pl={["70px","70px", "250px"]} pr={["70px","70px", "250px"]} mt={["40px","40px","80px"]} mb={["40px","40px","80px"]}>
        <InfoSection gridTemplateColumns="40% 60%" gridColumnGap="50">
            <InfoSection.Left>
                <Flex bg="primary" p="30" width="100%" borderRadius="10px" flexDirection="column">
                    <Text variant="small-regular" appearance="paper">{heading}</Text>
                    <Flex width="95%">
                        <Text variant="large-regular" appearance="paper">{subheading}</Text>
                    </Flex>
                    <GridBox gridTemplateColumns="10% auto" gridColumnGap="20" gridRowGap="20">
                        <Svg as="icon-email"></Svg>
                        <Text variant="x-small-regular" appearance="paper">{email}</Text>
                        <Svg as="icon-phone"></Svg>
                        <Text variant="x-small-regular" appearance="paper">{phone}</Text>
                        <Svg as="icon-address"></Svg>
                        <Text variant="x-small-regular" appearance="paper">{address}</Text>
                    </GridBox>
                    
                </Flex>
            </InfoSection.Left>
            <InfoSection.Right justifyContent="start" alignItems="start">
                <Flex flexDirection="column" >
                    <Text variant="small-regular">{label}</Text>
                    <Flex flexDirection="column" justifyContent="start">
                        <Text variant="h3" appearance="tertiary">{title}</Text>
                        <GridBox gridTemplateColumns="1fr 1fr" gridColumnGap="30">
                            <List 
                                items={textInputs}
                                render={
                                    ({ item, i})=> <input key={i} {...item}  className="input-text"></input> 
                                }
                            />
                        </GridBox>
                        <Flex mt="10">
                            <List 
                                items={textAreaInput}
                                render={
                                    ({ item, i})=> <textarea key={i} {...item}  className="input-textarea"></textarea> 
                                }
                            />  
                        </Flex>
                        <Flex width="50%" mt="20">
                            <Button {...cta}> {cta.text}</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </InfoSection.Right>     
        </InfoSection>
    </Flex>
    )
}

export default GetInTouchSection;
