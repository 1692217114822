import { oneOf } from 'prop-types';
import styled from 'styled-components';
import { rendersAs, variant } from './styleMixin';

import {
  flexbox,
  grid,
  background,
  border,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

export const Text = styled.p.attrs((props) => ({
  as: props.as || rendersAs(props),
  className: 'atom-text',
  fontFamily: 'generalgrotesque',
}))`
  ${variant}
  ${background}
  ${border}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  ${system({ textTransform: true })}
  text-decoration: ${(props) => props.textDecoration}
`;

Text.displayName = 'Text';

Text.defaultProps = {
  variant: 'body',
};

Text.propTypes = {
  appearance: oneOf(['primary', 'secondary', 'paper', 'tertiary', 'dark', 'quarter']),
  variant: oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'large-bold',
    'large-regular',
    'large-thin',
    'large-light',
    'medium-bold',
    'medium-regular',
    'normal-bold',
    'normal-regular',
    'small-bold',
    'small-regular',
    'x-small-bold',
    'x-small-regular',
    'body',
    'body-small',
    'body-xsmall',
    'overline'
  ]),
};

export default Text;
