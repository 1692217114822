import { createGlobalStyle } from 'styled-components';

import theme from './theme';


const GlobalStyle = createGlobalStyle`
  * {
    font-family: ${theme.fonts.montserrat}
  }
  html,body {
    margin: 0;
    height:100%;
    width:100%;
    padding:0;
    background: radial-gradient(58.95% 26.89% at 108.95% 28.55%, ${theme.colors.blues.sky} 0%, ${theme.colors.greys.sand} 100%)
  }

  .input-text:focus, .input-select:focus, .input-textarea:focus, .search-bar input[type=text]:focus {
    border-color: #3081c8;
  }
  .input-text, .input-textarea{
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    outline: none;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background: rgb(255, 255, 255);
  }
`;

export default GlobalStyle;
