import * as React from "react"

const PhoneIcon = (props) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={36} rx={4} fill="#fff" />
    <path
      d="M21.75 9.25A1.25 1.25 0 0 1 23 10.5v15a1.25 1.25 0 0 1-1.25 1.25h-7.5A1.25 1.25 0 0 1 13 25.5v-15a1.25 1.25 0 0 1 1.25-1.25h7.5ZM14.25 8a2.5 2.5 0 0 0-2.5 2.5v15a2.5 2.5 0 0 0 2.5 2.5h7.5a2.5 2.5 0 0 0 2.5-2.5v-15a2.5 2.5 0 0 0-2.5-2.5h-7.5Z"
      fill="#00BDE0"
    />
    <path
      d="M18 25.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
      fill="#00BDE0"
    />
  </svg>
)

export default PhoneIcon
