import React from "react";
import styled from "styled-components";
import { Flex, GridBox } from '../../atoms'

const Wrapper = styled(GridBox)``

const InfoSection = ({gridTemplateColumns, ...rest}) => <Wrapper gridTemplateColumns={gridTemplateColumns}  {...rest} />

InfoSection.Left =  styled(Flex)``
InfoSection.Right =  styled(Flex)``

InfoSection.defaultProps = {
    as: 'article',
    gridTemplateColumns: '1fr 1fr',
}

InfoSection.Left.defaultProps = {
    p: 10
}
InfoSection.Right.defaultProps = {
    p: 10,
    alignItems: 'center',
    justifyContent: 'center'
}

export default InfoSection;