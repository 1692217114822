import React from "react";
import { Flex } from '../../atoms'
import styled from "styled-components";
import { flexbox,layout, border, position } from 'styled-system'

const Image = styled.img`${flexbox}${layout} ${border} ${position}`

const Container = styled(Flex)``

const LeaftImage = ({source, ...rest}) => (
    <Container {...rest}>
        <Image src={source} {...rest}></Image>
    </Container>
)

Image.defaultProps = {

    borderTopLeftRadius:"60px",
    left:"-5px",
    top:"5px",
    position:"absolute",
    borderBottomRightRadius:"60px"
}

Container.defaultProps = {
    border:"2px solid",
    borderColor:"primary",
    borderTopLeftRadius:"60px",
    padding:"-5px",
    paddingBottom: "10px",
    position:"relative",
    borderBottomRightRadius:"60px"
}
export default LeaftImage;
