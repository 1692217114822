import { gql } from "@apollo/client";

const PERKS_FRAGMENT = gql`
  fragment PerkFragment on PerksModel{
    icon,
    title,
    description,
  }
`

export default PERKS_FRAGMENT;