import { lighten } from 'polished';
export const colors = {
  white: '#fff',

  blues: {
    mist: '#E4EDF3',
    sky: '#D3EFF4',
    beach: '#00BDE0',
    shower: '#0795AB',
    midnight: '#404D60',
    sail: '#004767'

  },
  greens: {
    mint: '#00E0AA',
  },
  greys: {
    sand: '#F8F9F9',
    white12: '#333333',
    white30: '#4F4F4F',
    white60: '#BDBDBD',
    dusk: '#828282',
    dark: '#2D2D2D'
  },
  oranges: {
    sunset: '#E08600',
  },
  reds: {
    fire: '#E0360D',
  },
  transparent: 'transparent'
};

export const colorTheme = {
  paper: colors.white,
  primary: colors.blues.beach,
  secondary: colors.blues.shower,
  tertiary: colors.blues.midnight,
  quarter: colors.blues.sail,
  dark: colors.greys.dark,
  interactions: {
    light: {
      default: {
        base: '',
        hover: '',
        disabled: '',
      },
      destructive: {
        base: '',
        hover: '',
        disabled: '',
      },
    },
    dark: {
      default: {
        base: '',
        hover: '',
        disabled: '',
      },
      destructive: {
        base: '',
        hover: '',
        disabled: '',
      },
    },
  },
  success: colors.greens.mint,
  danger: colors.reds.fire,
  disabled: lighten(0.35, colors.greys.dusk),
  info: colors.blues.midnight,
};
