import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

export const WithLottie = (path) => {
    const lottieRef = useRef();
    useEffect(()=>{
        lottie.loadAnimation({
            container: lottieRef.current, // the dom element that will contain the animation
            loop: true,
            autoplay: true,
            path
          });
    },[]);

    return (Component) => <Component ref={lottieRef}/>
}