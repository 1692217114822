


import { gql } from "@apollo/client";

const ASIDE_FRAGMENT = gql`
  fragment AsideFragment on AsideModel{
    foreground{
        url
    },
    ctaText,
    specTitle,
    specDescription:spectDescription,
    counters:countersCollection(limit:3){
        items{
            name,
            value
        }
    }
  }
`

export default ASIDE_FRAGMENT;