import { variant as styledVariant } from 'styled-system';


const textFamily = (isHeading, {questrial, montserrat}) => `${(isHeading)? questrial : montserrat} !important`;


export const variant = ({
    fontSize,
    appearance,
    color,
    mb,
    theme: { colors, fontSizes,fonts, space, fontWeights, letterSpacings },
  }) =>
    styledVariant({
      prop: 'variant',
      variants: {
        h1: {
          fontFamily: textFamily(true, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[48],
          fontWeight: fontWeights.regular,
          lineHeight: space[52],
          //letterSpacing: [letterSpacings[-1], letterSpacings[-2]],
          marginTop: space[0],
          mb: space[mb] || space[32],
        },
        h2: {
          fontFamily: textFamily(true, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[40],
          fontWeight: fontWeights.regular,
          lineHeight: space[44],
          // letterSpacing: [letterSpacings[-0.75], letterSpacings[-1.25]],
          marginTop: space[0],
          mb: space[mb] || space[32],
        },
        h3: {
          fontFamily: textFamily(true, fonts),
          color: color || colors[appearance] || colors.primary,
          fontSize: fontSize || fontSizes[32],
          fontWeight: fontWeights.regular,
          lineHeight: space[35],
          //letterSpacing: letterSpacings[-0.25],
          marginTop: space[0],
          mb: space[mb] || space[32],
        },
        h4: {
          fontFamily: textFamily(true, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[24],
          lineHeight: space[26],
          fontWeight: fontWeights.regular,
          // letterSpacing: letterSpacings[0],
          marginTop: space[0],
          mb: space[mb] || space[32],
        },
        h5: {
          fontFamily: textFamily(true, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[20],
          lineHeight: space[22],
          fontWeight: fontWeights.regular,
          // letterSpacing: letterSpacings[0],
          // marginTop: space[0],
          // mb: space[mb] || space[32],
          marginTop: space[0],
          mb: space[mb] || space[32],
        },
        'large-bold': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[20],
          lineHeight: space[28],
          fontWeight: fontWeights.bold,
          // letterSpacing: letterSpacings[0],
          // marginTop: space[0],
          // mb: space[mb] || space[32],
        },
        'large-regular': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[20],
          lineHeight: space[28],
          fontWeight: fontWeights.regular,
        },
        'large-thin': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[20],
          lineHeight: space[28],
          fontWeight: fontWeights.thin,
        },
        'large-light': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[20],
          lineHeight: space[28],
          fontWeight: fontWeights.light,
        },
        'medium-bold': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[18],
          fontWeight: fontWeights.bold,
          lineHeight: space[25],
        },
        'medium-regular': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[18],
          fontWeight: fontWeights.regular,
          lineHeight: space[25],
        },
        'normal-bold': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[16],
          fontWeight: fontWeights.bold,
          lineHeight: space[22],
        },
        'normal-regular': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[16],
          fontWeight: fontWeights.regular,
          lineHeight: space[22],
        },
        'small-bold': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[14],
          fontWeight: fontWeights.bold,
          lineHeight: space[19],
        },
        'small-regular': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[14],
          fontWeight: fontWeights.regular,
          letterSpacing: letterSpacings[0],
          lineHeight: space[19],
        },
        'x-small-bold': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[12],
          fontWeight: fontWeights.bold,
          lineHeight: space[16],
        },
        'x-small-regular': {
          fontFamily: textFamily(false, fonts),
          color: color || colors[appearance] || color || colors.primary,
          fontSize: fontSize || fontSizes[12],
          fontWeight: fontWeights.regular,
          lineHeight: space[16],
        },
       
        // overline: {
        //   color: color || colors[appearance] || color || colors.primary,
        //   fontSize: fontSizes[12],
        //   fontWeight: fontWeights.semiBold,
        //   // letterSpacing: letterSpacings[1],
        //   lineHeight: space[16],
        //   textTransform: 'uppercase',
        // },
      },
    });
  
  export const rendersAs = (props) => {
    switch (props.variant) {
      case 'h1':
        return 'h1';
  
      case 'h2':
        return 'h2';
  
      case 'h3':
        return 'h3';
  
      case 'h4':
        return 'h4';
  
      case 'h5':
        return 'h5';
  
      case 'h6':
        return 'h6';
  
      case 'body':
        return 'p';
  
      case 'body-small':
        return 'p';
  
      case 'body-xsmall':
        return 'p';
  
      case 'overline':
        return 'span';
  
      default:
        return 'p';
    }
  };