import React from "react";
import { Flex, Text, Gif } from '../../atoms'
import { InfoSection } from "../../molecules";
import ReactMarkdown from "react-markdown";

const WhatWeDoSection = ({
    title,
    description
}) => (
    <Flex flexDirection="column" as="section" id="what-we-do" 
     pl={["40px","40px", "250px"]} 
     pr={["40px","40px", "250px"]} 
     mt={["40px","40px","80px"]}
     mb={["40px","40px","80px"]}>
        <InfoSection  gridTemplateColumns={["1fr","1fr", "1fr 1fr"]}>
            <InfoSection.Left>
                <Flex flexDirection="column" pr="10px" pt={[0,0,100]}> 
                    <Flex width={["100%", "100%", "90%"]} >
                        <Text variant={["h3","h3","h2"]} textAlign={["center", "center", "left"]} >{title}</Text>
                    </Flex>
                    <Flex width={["100%","100%","80%"]}>
                        <Text variant="medium-regular" appearance="tertiary" textAlign={["center", "center", "left"]}>
                            <ReactMarkdown>
                                {description}
                            </ReactMarkdown>
                        </Text>
                    </Flex>
                </Flex>
            </InfoSection.Left>
            <InfoSection.Right gridRow={["1","1","unset"]}>
                <Gif source='assets/gif/animation-laptop.json'/>
            </InfoSection.Right>
               
        </InfoSection>
    </Flex>
)

export default WhatWeDoSection;
