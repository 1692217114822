import * as React from "react"

const HandShakeIcon = (props) => (
  <svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" stroke="#03BFCB" strokeWidth={2}>
      <path d="m73.929 68.32-.804-.971c2.45-1.984 3.388-3.85 4.213-5.494.668-1.33 1.299-2.586 2.602-3.51l.74 1.02c-1.05.74-1.561 1.762-2.208 3.05-.842 1.67-1.887 3.75-4.547 5.905h.004ZM46.996 75.328a3.02 3.02 0 0 1-2.132-.875 2.967 2.967 0 0 1 0-4.222l2.365-2.339a3.046 3.046 0 0 1 4.268 0 2.967 2.967 0 0 1 0 4.222l-2.364 2.34a3.028 3.028 0 0 1-2.133.874h-.004Zm2.365-7.05c-.448 0-.897.167-1.235.506l-2.365 2.339a1.722 1.722 0 0 0 0 2.448c.681.673 1.79.673 2.475 0l2.364-2.34a1.722 1.722 0 0 0 0-2.447 1.745 1.745 0 0 0-1.235-.507h-.004Z" />
      <path d="M43.672 71.993a3.003 3.003 0 0 1-2.132-.874 2.967 2.967 0 0 1 0-4.222l.377-.373a3.046 3.046 0 0 1 4.268 0c.566.561.88 1.31.88 2.11 0 .799-.313 1.547-.88 2.108l-.377.373a3.028 3.028 0 0 1-2.132.874l-.004.004Zm.377-5.084c-.449 0-.897.168-1.236.507l-.376.372a1.722 1.722 0 0 0 0 2.448c.681.674 1.79.674 2.474 0l.377-.373c.33-.326.508-.757.508-1.221 0-.465-.182-.9-.508-1.222a1.745 1.745 0 0 0-1.235-.507l-.004-.004ZM81.107 59.557a.634.634 0 0 1-.478-.213L69.894 47.159a.625.625 0 0 1 .063-.887l5.275-4.552.833.945-4.8 4.139 9.902 11.239 1.349-1.163.833.945-1.827 1.578a.63.63 0 0 1-.415.154Z" />
      <path d="m40.884 67.641-.228-.192c-.914-.77-1.007-.85-1.48-1.322-1.481-1.456-2.387-3.046-3.013-4.268-.22-.427-.418-.841-.609-1.235-.38-.795-.71-1.477-1.079-2.016-.02-.03-.038-.063-.054-.097-.005-.008-.022-.025-.034-.046l1.015-.753c.047.059.101.134.152.23.41.611.77 1.356 1.15 2.143.187.385.382.79.597 1.209.58 1.138 1.421 2.615 2.775 3.946.44.435.512.498 1.409 1.255l.228.192-.825.954h-.004ZM56.831 48.323c-1.81-1.298-4.25-1.67-6.53-.988a9.06 9.06 0 0 1-.44.121c-2.365.678-4.214.887-5.466.611a1.862 1.862 0 0 1-.334-.083c-.038-.013-.08-.026-.131-.05l.419-.808-.132.25.174-.602a.58.58 0 0 1 .084.026c.043.012.085.025.131.029.017 0 .03.004.047.008 1.028.234 2.712.025 4.873-.594.144-.038.266-.071.39-.109 2.668-.795 5.524-.355 7.656 1.172l-.745 1.017h.004ZM67.043 74.286a3.003 3.003 0 0 1-2.132-.874l-2.364-2.34.897-.886 2.364 2.338c.681.674 1.79.674 2.475 0a1.722 1.722 0 0 0 0-2.447l-4.4-4.356.897-.887 4.4 4.356a2.967 2.967 0 0 1 0 4.222 3.028 3.028 0 0 1-2.132.874h-.005Z" />
      <path d="M71.54 72.115a3.021 3.021 0 0 1-2.133-.875l-5.528-5.515.9-.883 5.525 5.515c.681.674 1.79.674 2.47 0a1.722 1.722 0 0 0 0-2.448l-.376-.372.897-.887.376.372a2.967 2.967 0 0 1 0 4.222 3.028 3.028 0 0 1-2.132.875v-.004ZM63.598 77.546c-.808 0-1.565-.31-2.132-.87l-2.618-2.59.896-.888 2.619 2.59c.33.327.765.503 1.235.503.47 0 .91-.18 1.235-.502.33-.327.508-.758.508-1.222 0-.465-.182-.9-.508-1.222l-4.425-4.377.897-.887 4.425 4.377c.567.56.88 1.31.88 2.109 0 .799-.313 1.548-.88 2.109a3.01 3.01 0 0 1-2.132.87ZM34.062 59.59a.623.623 0 0 1-.414-.154l-1.828-1.573.834-.946 1.349 1.163 9.903-11.243-4.802-4.138.834-.946 5.279 4.553a.628.628 0 0 1 .063.887l-10.736 12.18a.634.634 0 0 1-.478.214l-.004.004Z" />
      <path d="M72.398 67.537 59.67 54.947a1.065 1.065 0 0 0-1.498-.026c-1.028.971-2.474 2.34-3.413 3.222a132.6 132.6 0 0 0-.927.875 2.756 2.756 0 0 1-1.988.761 2.776 2.776 0 0 1-1.95-.85l-.322-.33c-.08-.088-.16-.175-.232-.284a2.728 2.728 0 0 1 .313-3.607l6.874-6.503c.346-.326.719-.631 1.104-.903 2.123-1.523 4.983-1.963 7.635-1.172 2.53.757 4.488.987 5.52.648l.402 1.193c-1.316.435-3.43.218-6.286-.636-2.276-.678-4.716-.31-6.531.992a8.312 8.312 0 0 0-.965.79l-6.873 6.503a1.48 1.48 0 0 0-.165 1.962c.008.013.02.026.03.042.029.046.067.084.105.126l.309.318c.28.288.656.452 1.062.464a1.51 1.51 0 0 0 1.078-.414c.064-.059.423-.398.93-.879.94-.887 2.387-2.25 3.415-3.222a2.368 2.368 0 0 1 3.278.046L73.299 66.65l-.897.887h-.004ZM50.44 78.588c-.808 0-1.565-.31-2.132-.87a2.967 2.967 0 0 1 0-4.222l2.99-2.959a3.01 3.01 0 0 1 2.132-.87c.808 0 1.565.31 2.132.87.567.56.88 1.31.88 2.11 0 .798-.313 1.547-.88 2.108l-2.99 2.958a3.01 3.01 0 0 1-2.132.87v.005Zm2.99-7.666c-.47 0-.91.18-1.235.502l-2.99 2.959a1.722 1.722 0 0 0 0 2.447c.33.327.765.502 1.235.502.47 0 .91-.18 1.235-.502l2.99-2.958c.33-.326.508-.757.508-1.222 0-.464-.182-.9-.508-1.222a1.736 1.736 0 0 0-1.235-.502v-.004Z" />
      <path d="M56.13 79.625a3.02 3.02 0 0 1-2.136-.866 2.951 2.951 0 0 1-.347-3.795c.089-.146.215-.284.334-.41l1.417-1.402a3.015 3.015 0 0 1 2.136-.874c.592 0 1.168.167 1.663.49l.042.029a3.069 3.069 0 0 1 .77.761 2.947 2.947 0 0 1-.343 3.812l-1.4 1.393c-.148.147-.3.268-.461.364a2.967 2.967 0 0 1-1.671.507l-.004-.009Zm1.4-6.096c-.47 0-.91.18-1.24.506l-1.4 1.385c-.059.063-.135.147-.181.222-.495.715-.415 1.64.181 2.226.326.322.766.498 1.24.498.355 0 .694-.105.977-.301.106-.067.178-.122.254-.201l1.4-1.394a1.723 1.723 0 0 0 .203-2.2 1.445 1.445 0 0 0-.203-.24 1.594 1.594 0 0 0-.245-.204c-.013-.008-.026-.017-.034-.025a1.776 1.776 0 0 0-.952-.276v.004Z" />
    </g>
    <path
      d="M27.977 60.173a1.5 1.5 0 1 0 3 0h-3Zm1.5-22.696v-1.5a1.5 1.5 0 0 0-1.5 1.5h1.5Zm37 0v1.5a1.5 1.5 0 0 0 1.5-1.5h-1.5Zm1.5-4.304a1.5 1.5 0 0 0-3 0h3Zm-37 27V37.477h-3v22.696h3Zm-1.5-21.196h37v-3h-37v3Zm38.5-1.5v-4.304h-3v4.304h3ZM87.977 57.173a1.5 1.5 0 0 0-3 0h3Zm-1.5 22.695v1.5a1.5 1.5 0 0 0 1.5-1.5h-1.5Zm-37 0v-1.5a1.5 1.5 0 0 0-1.5 1.5h1.5Zm-1.5 4.305a1.5 1.5 0 1 0 3 0h-3Zm37-27v22.695h3V57.173h-3Zm1.5 21.195h-37v3h37v-3Zm-38.5 1.5v4.305h3v-4.305h-3Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M66.477 33.173a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM49.477 92.173a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
      stroke="#fff"
      strokeWidth={3}
    />
    <path
      d="M26.707 32.302A39.577 39.577 0 0 0 19 55.83c0 18.743 12.97 34.456 30.425 38.654m41.378-62.182A39.576 39.576 0 0 1 98.51 55.83c0 18.743-12.97 34.456-30.425 38.654"
      stroke="#00BDE0"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M29.547 33.113a4.057 4.057 0 1 1 0-8.113 4.057 4.057 0 0 1 0 8.113ZM87.963 25a4.057 4.057 0 1 1 0 8.113 4.057 4.057 0 0 1 0-8.113Z"
      stroke="#00BDE0"
      strokeWidth={3}
    />
    <path opacity={0.1} fill="#3CE1CD" d="M61.093 44.784v-8.092h8.092v8.092z" />
    <path opacity={0.4} fill="#3CE1CD" d="M71.523 44.785v-8.092h8.092v8.092z" />
    <path opacity={0.7} fill="#3CE1CD" d="M81.955 44.784v-8.092h8.092v8.092z" />
    <path fill="#3CE1CD" d="M92.384 44.784v-8.092h8.092v8.092z" />
    <path
      opacity={0.1}
      fill="#3CE1CD"
      d="M54.484 68.652v8.092h-8.092v-8.092z"
    />
    <path opacity={0.4} fill="#3CE1CD" d="M50.29 81.124v8.092h-8.093v-8.092z" />
    <path opacity={0.7} fill="#3CE1CD" d="M39.86 81.124v8.092h-8.092v-8.092z" />
    <path fill="#3CE1CD" d="M29.43 81.125v8.092h-8.092v-8.092z" />
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(29.477 40.173)"
          d="M0 0h57v41H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default HandShakeIcon
