import React from "react";
import { Flex, Text, GridBox, List, Svg } from '../../atoms'
import { HeaderInfoSection, InfoSection, Card, Steps } from "../../molecules";

const services = [
    {
        icon: 'icon-process',
        title: 'Describe requirements',
        description: `Share the needs of what you are looking for.
        We inquire you questions from a soft and tech standpoint, and give rates and
        accessibility.`
    },
    {
        icon: 'icon-bow',
        title: 'Select Candidates',
        description: `Inside two weeks of the understanding, you get the primary
        pool of candidates to choose your favorite/s. Then, we arrange interviews for you to
        meet and assess them.`
    },
    {
        icon: 'icon-handshake',
        title: 'Start working',
        description: `We handle the administrative onboarding process. You fair welcome
        your Citizen and hit the ground running along with your technical needs as part of your
        group.`
    }
]

const steps = [
    {
        type: 'primary',
        value: 1,
        space: 15,
        lineSize: '35'
    },
    {
        type: 'paper',
        value: 2,
        space: 16,
        lineSize: '40'
    },
    {
        type: 'paper',
        value: 3,
        noLine: true
    } 
]

const OurFormulaSection = (props) => {
    return (<Flex flexDirection="column" as="section" id="our-formula"  
        pl={["40px","40px", "250px"]} 
        pr={["40px","40px", "250px"]} 
        mt={["40px","40px","80px"]} 
        mb={["40px","40px","80px"]}>
        <HeaderInfoSection>
            <HeaderInfoSection.Title>
                <Text variant="small-regular">// OUR FORMULA</Text>
            </HeaderInfoSection.Title>
        </HeaderInfoSection>
        <InfoSection gridTemplateColumns={["1fr","1fr","40% 60%"]}>
            <InfoSection.Left>
                <Flex flexDirection="column" alignItems={["center", "center", "start"]} justifyContent={["center", "center", "start"]}>
                    <Text variant="h3" appearance="tertiary">Work process</Text>
                    <Text variant="medium-regular" appearance="tertiary" textAlign={["center", "center", "left"]}>
                        Simplifying the process that provides highly innovated IT solutions to adds value to your business.
                    </Text>
                </Flex>
                
            </InfoSection.Left>
            <InfoSection.Right>
                <Flex>
                    <Flex mt="8em" pr="20" display={["none","none","inline"]}>
                        <Steps listItem={steps} direction="vertical" />
                    </Flex>
                    <GridBox gridTemplateRows="1fr 1fr 1fr" gridGap="50">
                        <List 
                            items={services}
                            render={
                                ({ item, i })=>(
                                    <Card key={i} p="10">
                                        <Card.Header alignItems="center" justifyContent="center">
                                            <Svg as={item.icon} />
                                            <Text variant="h5" appearance="dark" mt="10" mb="10">{item.title}</Text>
                                        </Card.Header>
                                        <Card.Body  m="0 auto">
                                            <Text variant="normal-regular" textAlign={["center", "center", "unset"]} appearance="tertiary">{item.description}</Text>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                        />
                    </GridBox>
                </Flex>
            </InfoSection.Right>     
        </InfoSection>
    </Flex>
)
}

export default OurFormulaSection;
