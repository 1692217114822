/* eslint-disable react/forbid-foreign-prop-types */
import styled from 'styled-components';
import propTypes from '@styled-system/prop-types';
import { outlineLayout } from '../../../styles/outlineLayout';
import {
  background,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

// NOTE: This seems to resolve the 200 Classes error
const Flex = styled('div')(
  { display: 'flex' },
  outlineLayout,
  compose(
    background,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    border,
    color,
    compose,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    typography,
  ),
);

Flex.displayName = 'Flex';

export const FlexPropTypes = {
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.gridColumn,
  ...propTypes.gridRow,
  ...propTypes.layout,
  ...propTypes.margin,
  ...propTypes.space,
};

Flex.propTypes = FlexPropTypes;

export default Flex;




