import React from "react";
import { Flex, Text, GridBox, List, Svg } from '../../atoms'
import { HeaderInfoSection, Card } from "../../molecules";

const OurServicesSection = ({ title, label, perks }) => {
    return(<Flex flexDirection="column" as="section" id="our-services" 
        pl={["40px","40px", "250px"]}
        pr={["40px","40px", "250px"]} 
        mt={["40px","40px","80px"]} 
        mb={["40px","40px","80px"]}>
        <HeaderInfoSection>
            <HeaderInfoSection.Title>
                <Text variant="small-regular">{label}</Text>
            </HeaderInfoSection.Title>
            <HeaderInfoSection.Description>
                <Text variant="medium-regular" appearance="tertiary">
                   {title}
                </Text>
            </HeaderInfoSection.Description>
        </HeaderInfoSection>
     
        <GridBox mt="48px" gridTemplateColumns={["1fr","1fr 1fr", "1fr 1fr 1fr"]} gridGap="40">
            <List 
                items={perks.items}
                render={
                    ({ item, i })=>(
                        <Card key={i}>
                            <Card.Header p="0" alignItems="center" justifyContent="center">
                                <Svg as={item.icon} />
                                <Text variant="h5" appearance="dark" mt="20" mb="20">{item.title}</Text>
                            </Card.Header>
                            <Card.Body width="75%" m="0 auto">
                                <Text variant="normal-regular" 
                                      appearance="tertiary" 
                                      textAlign="center">{item.description}</Text>
                            </Card.Body>
                        </Card>
                    )
                }
            />
        </GridBox>
    </Flex>
)
}

export default OurServicesSection;
