import { gql } from "@apollo/client";

export const GET_CAREERS_PAGE = gql`
  query GetCareersPage {
    jobView:jobViewModelCollection{
      items{
        title,
        description{
            json
        },
        location,
        department,
        type
      }
    }
  }
`

export default GET_CAREERS_PAGE;