import React from "react";
import { renderAs } from './styleMixin'
import PropTypes from 'prop-types'

const Svg = ({as, ...rest}) => renderAs(as)(rest)
export default Svg;


Svg.propTypes = {
    as: PropTypes.oneOf([
        'icon-above-pointer',
        'icon-arrow-down',
        'icon-address',
        'icon-avatar-tech',
        'icon-bow',
        'icon-code-file',
        'icon-email',
        'icon-facebook',
        'icon-instagram',
        'icon-linkedin',
        'icon-handshake',
        'icon-phone',
        'icon-process',
        'icon-logo',
        'icon-sandwich',
        'icon-close'
    ])
}
