import React from "react";
import styled from "styled-components";
import { Flex } from '../../atoms'
import { color } from 'styled-system'

const Container = styled(Flex)`${color}`

const Card = ({...rest}) => <Container  {...rest} />
Card.Header = styled(Flex)``
Card.Body = styled(Flex)`` 
Card.Footer = styled(Flex)``

Card.Header.defaultProps = {
    flexDirection: 'column',
    p: 10,
}

Card.Body.defaultProps = {
    p: 10
}
Card.Footer.defaultProps = {
    p: 10
}

Card.defaultProps = {
    position: 'relative',
    p: 0,
    m: 0,
    minWidth: 0,
    borderRadius: 12,
    padding: 10,
    flexDirection: 'column',
    bg: 'paper',
}
    
export default Card;