import React from "react";
import { Flex, Text, List, Button, GridBox, Svg } from '../../atoms'
import { Card, Steps, InfoSection } from '../../molecules'

const CareersTemplate = ({ careers }) => {

  const perks = {
    items: [
      {
        icon: 'icon-code-file',
        title: 'Work from home',
        description: 'Or your favourite place in the world! For us, remote is a mindset, not a place. Enjoy a healthy work-life balance by combining the flexibility of freelancing with the stability of a full-time job.'
      },
      {
        icon: 'icon-code-file',
        title: 'Multicultural Fun Environment',
        description: 'We keep things fun. Working remotely doesn’t mean being on your own. We like to meddle, share experiences, learn about each other’s countries and cultures and even organice gaming contests from time to time'
      },
      {
        icon: 'icon-code-file',
        title: 'Growth & learning opportunities',
        description: 'We care about your professional growth and always support learning initiatives. Keep up to date with the latest tech trends through our Webinars and remote talks, take courses online, etc.'
      }
    ]
  }

  const hiringProcess = [
    {
        icon: 'icon-process',
        title: 'Let’s get to know each other',
        description: `Our process will start by screening your profile and having an interview to get to know your education, professional experience, skills, English level, your interests and cultural fit for DevCity.`
    },
    {
        icon: 'icon-bow',
        title: 'Evaluating your skills',
        description: `This step involves a tech interview with our experts to understand your know-how working with specific technologies..`
    },
    {
        icon: 'icon-handshake',
        title: 'Meet your client',
        description: `Once you have passed the previous steps, you will be assigned to an exciting project with a client that will further evaluate your skills and fit for the position.`
    },
    {
        icon: 'icon-handshake',
        title: 'Welcome',
        description: `Congratulations! If you have received client approval you are now officially part of the #DevCitizen. Our People Care team will take care of your onboarding process and make sure you have everything you need to start your journey the right way.`
    }
]

const steps = [
  {
      type: 'primary',
      value: 1,
      space: 10,
      lineSize: '35'
  },
  {
      type: 'paper',
      value: 2,
      space: 10,
      lineSize: '40'
  },
  {
      type: 'paper',
      value: 3,
      space: 10,
      lineSize: '30'

  } ,
  {
      type: 'paper',
      value: 4,
      noLine: true
  } 
]


  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" as="section" id="what-we-do"
        pl={["40px", "40px", "250px"]}
        pr={["40px", "40px", "250px"]}
        mt={["40px", "40px", "80px"]}
        mb={["40px", "40px", "80px"]}>

        <Flex flexDirection="column">
          <Text variant="h4" m="0">Be part of a dynamic remote team!</Text>
          <Text variant="small-regular" m="0" mt="20" appearance="tertiary"> Join our multicultural and energetic team of top-notch IT professionals distributed across Latin America and collaborate on exciting US-based projects with leading companies.</Text>
        </Flex>

        <Flex mt="40" flexDirection="column">
          <Text variant="medium-regular" m="0">Check out our open positions!</Text>
          <Text variant="x-small-regular" m="0" mt="10" appearance="tertiary">Here you can see a list of job positions and can see their details, complete your information and apply directly.</Text>
        </Flex>

        <Flex mt="20" flexDirection="column">
          <List
            items={Object.values(careers)}
            render={({ item, i }) => (
              <Flex key={i} flexDirection="column">
                <Button as="a" variant="text" hasHover borderTop={i !== 0 ? '1px solid #00BDE0' : 'none'} p="10" pt="0">
                  <Text variant="small-bold" m="0" mt="10" appearance="tertiary">{item.title}</Text>
                  <Flex m="0" width="100%" justifyContent="space-between">
                    <GridBox gridTemplateColumns="1fr auto" gridGap="20">
                      <Flex>
                        <Text variant="x-small-bold" fontSize={10} fontWeight="600" m="0" appearance="tertiary">Location:</Text>
                        <Text variant="x-small-regular" fontSize={10} m="0" ml="5" appearance="tertiary">{item.location}</Text>
                      </Flex>
                      <Flex>
                        <Text variant="x-small-bold" fontSize={10} fontWeight="600" m="0" appearance="tertiary">Department:</Text>
                        <Text variant="x-small-regular" fontSize={10} m="0" ml="5" appearance="tertiary">{item.department}</Text>
                      </Flex>
                    </GridBox>
                    <Text variant="x-small-regular" fontSize={10} m="0" ml="5">View more</Text>
                  </Flex>
                  
                </Button>
              </Flex>
            )}
          />
        </Flex>

        <Flex flexDirection="column" mt="100" mb="0">
          <Text variant="h4" m="0">Why work with us? </Text>
          <GridBox mt="20" gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]} gridGap="40">
            <List
              items={perks.items}
              render={
                ({ item, i }) => (
                  <Card key={i} bg="none">
                    <Card.Header p="0" alignItems="start" justifyContent="start">
                      <Svg as={item.icon} />
                      <Text variant="medium-regular" appearance="tertiary" mt="20" mb="20">{item.title}</Text>
                    </Card.Header>
                    <Card.Body maxWidth="75%" p="0">
                      <Text variant="small-regular"
                        appearance="tertiary"
                        textAlign="left">{item.description}</Text>
                    </Card.Body>
                  </Card>
                )
              }
            />
          </GridBox>
        </Flex>

        <Flex flexDirection="column" as="section" mt={["40px","40px","80px"]} >
          <Text variant="h4" m="0">Our hiring process</Text>

          <Flex flexDirection="column" alignItems={["center", "center", "start"]} justifyContent={["center", "center", "start"]}>
            <Text variant="small-regular" appearance="tertiary" textAlign={["center", "center", "left"]}>
              Simplifying the process that provides highly innovated IT solutions to adds value to your business.
            </Text>

                    <GridBox gridTemplateColumns={["1fr","1fr","10% 30%"]}>
                        <Flex mt="5em" display={["none","none","inline"]}>
                            <Steps listItem={steps} direction="vertical" />
                        </Flex>
                        <GridBox gridTemplateRows="1fr 1fr 1fr 1fr" mt="50">
                            <List 
                                items={hiringProcess}
                                render={
                                    ({ item, i })=>(
                                        <Card key={i}  bg="none">
                                            <Card.Header alignItems="center" justifyContent="center" mt="10">
                                                <Text variant="h5" appearance="dark"  mb="0">{item.title}</Text>
                                            </Card.Header>
                                            <Card.Body  m="0 auto" >
                                                <Text variant="small-regular" textAlign={["center", "center", "unset"]} appearance="tertiary">{item.description}</Text>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            />
                        </GridBox>
                    </GridBox>
                </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CareersTemplate;
