import React from "react";
import styled from "styled-components";
import { Flex } from '../../atoms'

const Wrapper = styled(Flex)``


const HeaderInfoSection = ({...rest}) => <Wrapper  {...rest} />
HeaderInfoSection.Title = styled(Flex)``
HeaderInfoSection.Description = styled(Flex)``


Wrapper.defaultProps = {
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: 500
}

HeaderInfoSection.Title.defaultProps = {
    p: 10,
    pb: 30
}
HeaderInfoSection.Description.defaultProps = {
    p: 10,
}

export default HeaderInfoSection;