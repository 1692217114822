import * as React from "react"

const InstagramIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 5.193A4.8 4.8 0 0 0 5.191 10 4.8 4.8 0 0 0 10 14.807 4.8 4.8 0 0 0 14.806 10 4.8 4.8 0 0 0 10 5.193Zm0 7.931A3.13 3.13 0 0 1 6.874 10a3.13 3.13 0 0 1 3.124-3.124A3.13 3.13 0 0 1 13.123 10 3.13 3.13 0 0 1 10 13.124Zm5.003-9.248a1.121 1.121 0 1 0 0 2.245 1.12 1.12 0 0 0 1.038-1.552 1.12 1.12 0 0 0-1.038-.693ZM19.369 10c0-1.294.012-2.576-.06-3.867-.073-1.5-.415-2.831-1.512-3.928-1.1-1.1-2.428-1.44-3.928-1.512C12.575.62 11.293.632 10.002.632 8.707.632 7.425.62 6.133.693c-1.5.073-2.831.415-3.928 1.512C1.107 3.304.767 4.633.694 6.133.622 7.427.634 8.709.634 10s-.012 2.576.06 3.867c.073 1.5.415 2.831 1.512 3.928 1.1 1.1 2.428 1.44 3.928 1.512 1.294.073 2.576.061 3.867.061 1.294 0 2.576.012 3.868-.061 1.5-.073 2.83-.415 3.928-1.512 1.099-1.099 1.439-2.428 1.511-3.928.076-1.291.061-2.573.061-3.867Zm-2.062 5.527a2.977 2.977 0 0 1-.708 1.073c-.33.33-.647.537-1.073.708-1.233.49-4.16.38-5.527.38-1.366 0-4.296.11-5.529-.378a2.978 2.978 0 0 1-1.073-.708 2.997 2.997 0 0 1-.708-1.073c-.488-1.235-.377-4.163-.377-5.529 0-1.366-.11-4.296.377-5.529.171-.426.377-.745.708-1.073A3.03 3.03 0 0 1 4.47 2.69c1.233-.488 4.163-.377 5.53-.377 1.366 0 4.295-.11 5.528.377.427.17.745.377 1.074.708a3 3 0 0 1 .707 1.073c.488 1.233.378 4.163.378 5.529 0 1.366.11 4.294-.38 5.527Z"
      fill="#fff"
    />
  </svg>
)

export default InstagramIcon
