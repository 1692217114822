import * as React from "react"

const AbovePointerIcon = (props) => (
  <svg
    width={140}
    height={140}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={44}
      y={30}
      width={52}
      height={80}
      rx={26}
      stroke="url(#a)"
      strokeWidth={8}
    />
    <rect x={56} y={42} width={28} height={56} rx={14} fill="url(#b)" />
    <path fill="#fff" d="M72.222 82.475H67.81v-21.18h4.412z" />
    <path
      d="m80 65.984-3.12 3.12-9.984-9.984 3.12-3.12L80 65.984Z"
      fill="#fff"
    />
    <path
      d="m60 65.984 3.12 3.12 9.984-9.984-3.12-3.12L60 65.984ZM32 74v1.5a1.5 1.5 0 0 0 1.5-1.5H32Zm33.195-54.187a1.5 1.5 0 0 0-.39-2.974l.39 2.974ZM21 72.5a1.5 1.5 0 0 0 0 3v-3ZM33.5 74V56h-3v18h3Zm31.305-57.161C45.445 19.383 30.5 35.945 30.5 56h3c0-18.53 13.808-33.836 31.695-36.187l-.39-2.974ZM32 72.5H21v3h11v-3Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M70 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke="#fff"
      strokeWidth={3}
    />
    <path
      d="M108 66v-1.5a1.5 1.5 0 0 0-1.5 1.5h1.5Zm-33.195 54.187a1.5 1.5 0 1 0 .39 2.974l-.39-2.974ZM118 67.5a1.5 1.5 0 0 0 0-3v3ZM106.5 66v18h3V66h-3Zm-31.305 57.161C94.555 120.617 109.5 104.055 109.5 84h-3c0 18.529-13.808 33.836-31.695 36.187l.39 2.974ZM108 67.5h10v-3h-10v3Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M70 117a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
      stroke="#fff"
      strokeWidth={3}
    />
    <path
      opacity={0.1}
      fill="#3CE1CD"
      d="M17 108.876V98.495h10.381v10.381zM11 93.381V83h10.381v10.381z"
    />
    <path
      opacity={0.4}
      fill="#3CE1CD"
      d="M30.496 108.876v-10.38h10.381v10.38zM24.496 93.381v-10.38h10.381v10.38z"
    />
    <path
      opacity={0.7}
      fill="#3CE1CD"
      d="M43.99 108.876v-10.38h10.381v10.38zM37.99 93.381v-10.38h10.381v10.38z"
    />
    <path
      fill="#3CE1CD"
      d="M57.486 108.876v-10.38h10.381v10.38zM51.486 93.381v-10.38h10.381v10.38z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={70}
        y1={30}
        x2={70}
        y2={110}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BDE0" />
        <stop offset={1} stopColor="#53E4FF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={70}
        y1={42}
        x2={70}
        y2={98}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BDE0" />
        <stop offset={1} stopColor="#53E4FF" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default AbovePointerIcon
