import { gql } from "@apollo/client";

const BRIEF_ABOUT_US_FRAGMENT = gql`
  fragment BriefAboutUsFragment on BriefAboutUsModel{
    label,
    title,
    description
  }
`

export default BRIEF_ABOUT_US_FRAGMENT;