import * as React from "react"

const ArrowDownIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="m12 18.05-5.1-5.1.5-.5 4.6 4.6 4.6-4.6.5.5-5.1 5.1Zm0-5.95L6.9 7l.5-.5 4.6 4.6 4.6-4.6.5.5-5.1 5.1Z"
        fill="#00BDE0"
      />
    </g>
  </svg>
)

export default ArrowDownIcon