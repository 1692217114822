import React from "react";
import styled from "styled-components";
import { color } from 'styled-system'
import { Flex, Text, List } from '../../atoms'

const Circle = styled(Flex)``
const LineHorizonal = styled(Flex)`${color}`
const LineVertical = styled(Flex)`${color}`

const textColor = (type) => type === 'primary' ? 'paper' : 'primary';

const Step = ({value, direction, type,  space, lineSize, noLine}) => (
    <Flex 
        mb={direction === 'vertical' && !noLine ? `${space}em` : 0}
        mr={direction === 'horizontal' && !noLine ? `${space}em` : 0}
        >
        <Circle bg={ type }>
            <Text variant="h4" appearance={ textColor(type) } m="0 auto">{value}</Text>
        </Circle> 
        
        { !noLine && direction === 'horizontal' && <LineHorizonal width={`${lineSize}%`} /> }
        { !noLine && direction === 'vertical' && <LineVertical height={`${lineSize}%`} /> }
    </Flex>
)

const Steps = ({listItem, direction}) => (
    <Flex flexDirection={direction === 'vertical' ? 'column' : 'row'}>
        <List
            items={listItem}
            render={
                ({item, i})=>(<Step key={i} direction={direction} {...item}/>)
            }
        />
    </Flex>
    
)

Circle.defaultProps = {
    border: '1px solid black',
    borderRadius: '100px',
    height: '80px',
    width: '80px',
    gap: 8,
    bg: 'primary',
    borderColor: 'primary',
    p: 10,
    alignItems: 'center',
    position: 'relative',
    zIndex: '1000'
}
LineVertical.defaultProps = {
    border: '1px solid',
    color: 'blues.mist',
    position: 'absolute',
    ml:"50px",
    zIndex: '100',
}

LineHorizonal.defaultProps = {
    border: '1px solid',
    color: 'blues.mist',
    position: 'absolute',
    mt:"40px",
    zIndex: '100',
    
}
Step.defaultProps = {
    value: 1,
    direction: 'vertical',
    lineSize: '100%',
    space: 10,
    noLine: false,
    type: 'primary'
    
}
Steps.defaultProps = {
    listItem: [],
    direction: 'vertical',
}
export default Steps;