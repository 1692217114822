import React from 'react';
import ReactDOM from 'react-dom/client';
import { Home, Careers } from './components/pages';
import { Footer, NavBar } from './components/organisms'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from './hooks'
import GlobalStyle from './styles/globalStyle';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import './index.css'

const App = () => {
  const client = useApollo()
  
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <NavBar/>
        <Router >
          <Routes >
            <Route path="/" element={ <Home/>} />
            <Route path="/careers" element={<Careers/>} />
          </Routes>
        </Router>
        <Footer/>
      </ThemeProvider>
  </ApolloProvider>
  )
}

const LDProvider = withLDProvider({
  clientSideID: '6333058f711aa211c3bde9c0',
  user: {
    "key": "sdk-8dd25b24-d01b-4abb-800c-2f2721525924",
    "name": "devcityllc",
    "email": "devcityllc@gmail.com"
  },
})(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<LDProvider/>);