import {
    AbovePointerIcon,
    AddressIcon,
    ArrowDownIcon,
    AvatarTech,
    BowIcon,
    CodeFileIcon,
    EmailIcon,
    FacebookIcon,
    HandShakeIcon,
    InstagramIcon,
    LinkedinIcon,
    PhoneIcon,
    ProcessIcon,
    LogoIcon,
    SandwichIcon,
    CloseIcon
} from './icons'


const iconsMapper = {
    'icon-above-pointer': props => <AbovePointerIcon {...props} />,
    'icon-arrow-down': props => <ArrowDownIcon {...props} />,
    'icon-address': props => <AddressIcon {...props} />,
    'icon-avatar-tech': props => <AvatarTech {...props} />,
    'icon-bow': props => <BowIcon {...props} />,
    'icon-code-file': props => <CodeFileIcon {...props} />,
    'icon-email': props => <EmailIcon {...props} />,
    'icon-facebook': props => <FacebookIcon {...props} />,
    'icon-instagram': props => <InstagramIcon {...props} />,
    'icon-linkedin': props => <LinkedinIcon {...props} />,
    'icon-handshake': props => <HandShakeIcon {...props} />,
    'icon-phone': props => <PhoneIcon {...props} />,
    'icon-process': props => <ProcessIcon {...props} />,
    'icon-logo': props => <LogoIcon {...props} />,
    'icon-sandwich': props => <SandwichIcon {...props} />,
    'icon-close': props => <CloseIcon {...props} />
}

export const renderAs = (as) => (props) => iconsMapper[as](props)
  
 