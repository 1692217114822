import { gql } from "@apollo/client";

const OURBENEFITS_FRAGMENT = gql`
  fragment OurBenefitsFragment on OurBenefitsModel{
    label,
    title,
    description,
  }
`

export default OURBENEFITS_FRAGMENT;