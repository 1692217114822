import React from "react";
import { Flex, Text, Button, GridBox, Svg } from '../../atoms'

const Footer = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    
    return(
        <Flex flexDirection="column" as="footer" mt="80px">
            <GridBox gridTemplateColumns={["100%","50% 50%","50% 20% auto" ]}
                    backgroundImage="url('/assets/png/footer.png')"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    pb="56px"
                    pt="56px">
                <Flex flexDirection="column" pl={[0,0,"15rem"]} alignItems={["center","center","unset"]}>
                    <Svg as="icon-logo"/>
                    {/* <Text variant="x-small-regular" appearance="tertiary">devcityllc@gmail.com</Text> */}
                    <Flex width={["100%", "100%", "80%"]} justifyContent={["center", "center", "unset"]}>
                        <Text  variant="x-small-regular" appearance="tertiary">info@thedevcity.com</Text>
                    </Flex>
                    <Text  variant="x-small-regular" appearance="tertiary">+1(978)763-8595</Text>
                    
                    <GridBox gridTemplateColumns={["1fr 1fr","1fr 1fr","5% auto"]} gridGap={[10,10,"1.625rem"]} mt="10px">
                        <Button size="small" width="50px" onClick={()=>openInNewTab("https://www.linkedin.com/company/devcityllc/")} borderRadius="5px" borderWidth="0" p="10" pb="8">
                            <Svg as="icon-linkedin"/>
                        </Button>
                        <Button size="small" width="50px" onClick={()=>openInNewTab("https://www.instagram.com/thedevcity/")} borderRadius="5px" borderWidth="0" p="10" pb="8">
                            <Svg as="icon-instagram"/>
                        </Button>
                    </GridBox>
                </Flex>

                <Flex flexDirection="column" display={["none", "none", "inline"]}>
                    <Text variant="medium-regular" appearance="tertiary">Menu</Text>
                    <Flex flexDirection="column">
                        <Text  variant="x-small-regular" appearance="tertiary">HOME</Text>
                        <Text  variant="x-small-regular" appearance="tertiary">ABOUT</Text>
                        <Text  variant="x-small-regular" appearance="tertiary">SERVICES</Text>
                        <Text  variant="x-small-regular" appearance="tertiary">PRIVACY POLICY</Text>
                    </Flex>
                </Flex>

                <Flex flexDirection="column" alignItems={["center", "center", "start"]} display={["none", "none", "inline"]}>
                    <Text variant="medium-regular" appearance="tertiary">Services</Text>
                    <Flex flexDirection="column" alignItems={["center", "center", "unset"]}>
                        <Text  variant="x-small-regular" appearance="tertiary">Software Outsourcing</Text>
                        <Text  variant="x-small-regular" appearance="tertiary">Staff Augmentation</Text>
                        <Text  variant="x-small-regular" appearance="tertiary">Dedicated Teams & Support</Text>
                    </Flex>
                </Flex>
            </GridBox>

            <Flex height="100%" pt="20" pb="20" bg="paper" align-items="center" justifyContent="center">
                <Text variant="x-small-regular" appearance="tertiary">2022 © All rights reserved. DevCity LLC.</Text>
            </Flex>
        </Flex>
    )
}

export default Footer;
