import * as React from "react"

const SandwichIcon = (props) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.334 8.5h21.333m-21.333 8h21.333m-9.333 8h9.333"
      stroke="#00BDE0"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SandwichIcon
